.slider
{
    width: 90%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 100px;

}

.add-slid
{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 100px;
    justify-content: space-between;
    margin-bottom: 20px;
}

.new-p
{
    font-size: 20px !important;
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    padding: 10px 15px !important;
    border-radius: 5px !important;
    color: white !important;
    cursor: pointer !important;
    background-color: #000 !important;
}

.del-true
{
    background-color: red !important
}

.new-p:hover
{
    background-color: rgb(70, 67, 67) !important;
    transition: 0.3s;
}

.slider-list
{
    width: 100%;
    height: 600px;
    padding: 0 100px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll
}


.slid-one
{
    width: 100%;
    margin: 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background-color: rgb(73, 72, 72);
    color: white;
}
.slid-one img{
    width: 150px;
    height: 125px;
    padding: 10px;
}

.slid-one:hover
{
    background-color: #000;
    transition: 0.3s;
}

.slid-one p
{
    padding-top: 10px;
}

.slid-head
{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background-color: #000;
    color: white;
    font-size: 20px;
}
.slid-head p
{
    padding-top: 10px;
}

.func-btn
{
    width: 250px;
    display: flex;
}
.func-btn p
{
    padding-top: 0px;
    margin: 0 10px;
}

.slid-edit
{
    width: 80px;
    height: 40px;;
    padding: 10px;
    background-color: rgb(212, 212, 51);
    color:black;
    border-radius: 5px;
    font-size: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top:10px !important;
    cursor: pointer;
}

.slid-del
{
    width: 80px;
    height: 40px;;
    padding: 10px;
    background-color:red;
    color:black;
    border-radius: 5px;
    font-size: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top:10px !important;
    cursor: pointer;
}

.slid-res
{
    width: 80px;
    height: 40px;;
    padding: 10px;
    background-color:rgb(7, 218, 7);
    color:black;
    border-radius: 5px;
    font-size: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top:10px !important;
    cursor: pointer;
}

