.navs
{
    width: 300px;
    height: 100vh;
    background-color: #232227;
    color: white;
    border-right: 1px solid silver;
    z-index: 1;
    overflow: hidden;
}

.rp-nav
{
    width: 100%;
    height: 100vh;
    background-color: #232227;
    color: white;
    border-right: 1px solid silver;
    z-index: 1;
    transition: 0.3s

}

.nav-img
{
    width: 100%;
    height: 90px;
    background-color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.nav-img img
{
    width: 30%
}
.fa-bars
{
    position: absolute;
    top: 3%;
    left: 5%;
    font-size: 30px;
    display: none;
    cursor: pointer !important;
}

.hide-nv
{
    width: 0px !important;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
}

.show-nv
{
    width: 100% !important;
    opacity: 1;
    visibility: visible;
    transition: 0.3s;
}

.fa-xmark
{
    display: none;
    position: absolute;
    color: black;
    top: 35%;
    transform: translateX(-50%);
    right: 5%;
    font-size: 30px;
    cursor: pointer;
}

.sidel
{
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    color: white;
}
.sidel:hover
{
    color: white;
    text-decoration: none;
}
.sidel:hover span
{
    width: 100%;
}
.sidel span
{
    position: absolute;
    top: 0%;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #161618;
    z-index: -1;
    transition: 0.3s
}

.profile
{
    width: 100%;
    height: 90px;
}
@media(max-width:900px)
{
    .navs
    {
        width: 0px
    }
}