.login
{
    width: 100%;
    height: 100vh;
    background-color:  black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logged
{
    width: 40%;
    height: 80vh;
    border: 1px solid #410046;
    border-radius: 3px;
    position: relative;
    animation: logged-anime 0.3s;
}

@keyframes logged-anime
{
    from{margin-top: 800px;}
    to{margin-top: 0px;}
}

.form-log
{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.form-div
{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 30px;
    padding-top: 0px;
    overflow: hidden;
}

.form-div label
{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    font-size: 18px;
    font-weight: 400;
    line-height: 29px;
    margin-bottom: 0;
    color: #ff00d1;
}

.form-input
{
    width: 90%;
    height: 50px;
    outline: none;
    font-size: 20px;
    font-family: sans-serif;
    padding-left: 20px;
    border: 1px solid silver;
    border-radius: 3px;
    animation: input-anime 0.9s;
}

@keyframes input-anime
{
    from{margin-right: -500px;}
    to{ margin-right: 0px;} 
}


.log-usr
{
    font-size: 20px !important;
    font-family:Verdana, Geneva, Tahoma, sans-serif !important;
    font-weight: 500 !important;
    padding-bottom: 10px !important;
    animation: log-anime 0.9s;
    cursor: pointer;
}

.psw-log
{
    position: relative;
}
.bi-eys
{
    position: absolute;
    font-size: 20px;
    top: 44%;
    right: 15%;
    cursor: pointer;
    animation: input-anime 0.9s;
}

@keyframes log-anime
{
    from{margin-left: -300px;}
    to{ margin-left: 0px;} 
}




.form-sub
{
    width: 80%;
    height: 40px;
    border: none;
    border-radius: 3px;
    background-color: #ad0093;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 18px;
    line-height: 30px;
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s;
}
.form-sub:hover
{
    font-size: 19px;
    transition: 0.3s;
    animation: andro 1s infinite;
}


@keyframes andro
{
    from{
        background-color: #26001b;
    }
    to{
        background-color: #363636;
    }
}

.ent-img
{
    width: 100%;
    height: 90px;
    border-bottom: 1px solid silver;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0%;
    left: 0%;
}
.ent-img  img{
    width: 100px;
    padding: 10px;
}

.err-log
{
    border: 1px solid red;
    animation: err-log-anime 0.5s infinite;
}

@keyframes err-log-anime
{
    from{border: 2px solid #f9706a;}
    to{border: 2px solid #d17671;}
}

.lg-err
{
    width: 100% !important;
    color: white;
}

@media(max-width:900px)
{
    .logged
    {
        width: 80%;
    }
}